import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  WalletCategory,
  WalletDetailsProps,
} from '@interfaces/Wallets.interfaces';
import { ApiErrorPin } from '@services/auth/auth.interfaces';
import { UpdateWalletResponse, updateWalletValues } from '../WalletServices';
import { walletKeys } from './walletKeys';

interface UpdateWalletValues {
  walletId: string;
  payload: WalletCategory;
}

export const useUpdateWalletBalanceMutation = () => {
  const queryClient = useQueryClient();

  const organizationId = localStorage.getItem('organizationId') || '';

  return useMutation<
    UpdateWalletResponse,
    ApiErrorPin,
    UpdateWalletValues,
    unknown
  >({
    mutationFn: ({ payload, walletId }: UpdateWalletValues) =>
      updateWalletValues(walletId, payload),
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries({ queryKey: walletKeys.lists() });
      queryClient.setQueryData(
        walletKeys.detail({ walletId: variables.walletId, organizationId }),
        (oldData: WalletDetailsProps): WalletDetailsProps => ({
          ...oldData,
          balances: response.balances,
          totalBalance: response.totalBalance,
        })
      );
    },
  });
};
