import { SidebarFooter, SidebarSection } from '@components';
import { Button, PaymentMethod, Sidebar } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useTranslation } from 'react-i18next';
import { ManagePixKey } from '../drawerPixArea/components';
import { DrawerFundsInFlow } from './DrawerFundsIn.interface';
import { BillingDetail } from './components/BillingDetail';
import { FormCreateBilling } from './components/FormCreateBilling';

type PaymentMethod = '' | 'qr-code' | 'bank-slip';

export const DrawerFundsIn = () => {
  const { t } = useTranslation();
  const { params, openDrawer, closeDrawer, removeParams } = useDrawer();

  const handleFlow = (flow: PaymentMethod) => {
    openDrawer('funds-in', {
      flow,
    });
  };

  const flow = params?.flow as DrawerFundsInFlow;

  const sidebarTitle = {
    [DrawerFundsInFlow.BANK_SLIP]: t('billing.title-billing-bank-slip'),
    [DrawerFundsInFlow.QR_CODE]: t('billing.title-billing-pix'),
  };

  const flows = {
    [DrawerFundsInFlow.BANK_SLIP]: (
      <FormCreateBilling flow={flow} walletId={params?.walletId} />
    ),
    [DrawerFundsInFlow.QR_CODE]: (
      <ManagePixKey
        onBack={() => removeParams(['flow'])}
        walletId={params?.walletId || ''}
      />
    ),
  };

  return (
    <Sidebar
      open={true}
      title={sidebarTitle[flow] || t('general.funds-in')}
      icon="fa-regular fa-rectangle-history-circle-plus"
      onClose={closeDrawer}
    >
      {params?.billingId ? (
        <BillingDetail />
      ) : (
        <>
          {!!flow && <>{flows[flow]}</>}

          {!flow && (
            <>
              <SidebarSection
                titleSection={t('billing.select-payment-method')}
                subTitleSection={t('billing.choice-funds-in-option')}
              >
                <div className="flex flex-row gap-4">
                  <PaymentMethod
                    label={t('general.pix')}
                    onClick={() => handleFlow('qr-code')}
                  >
                    <i className="fa-brands fa-pix newPayments_icon-pix" />
                  </PaymentMethod>

                  <PaymentMethod
                    label={t('general.bank-slip')}
                    onClick={() => handleFlow('bank-slip')}
                  >
                    <i className="fa-regular fa-barcode newPayments_icon-pix" />
                  </PaymentMethod>
                </div>
              </SidebarSection>

              <SidebarFooter>
                <Button
                  type="button"
                  variant="tertiary"
                  size="large"
                  onClick={closeDrawer}
                >
                  {t('button.back')}
                </Button>

                <Button type="submit" size="large" disabled>
                  {t('button.finish')}
                </Button>
              </SidebarFooter>
            </>
          )}
        </>
      )}
    </Sidebar>
  );
};
