import { ColumnDef } from '@tanstack/react-table';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ICONS } from '@constants/icons';
import { CardTypeEnum } from '@enums/Card.enum';
import { TypePayment, typePaymentOptions } from '@enums/typePayment.enum';
import { useFilterColumns } from '@hooks/useFilterColumns';
import { Transactions } from '@interfaces/Transactions.interfaces';
import { WalletType } from '@interfaces/Wallets.interfaces';
import { eventsHandler } from '@lib/eventsHandler';
import { DrawerTransactionDetails } from '@modules/drawer';
import {
  Button,
  TableDate,
  TableIcon,
  TableItemTitleSub,
} from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { transactionKeys } from '@services/transactions/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import {
  bankSlipPayment,
  billingPayment,
  pixTransaction,
} from '@utils/transactionFormatFunctions';

export interface TransactionsWithId extends Transactions {
  id: string;
  additionalInfo?: {
    user?: {
      name: string;
      id: string;
    };
    isInternalTransaction?: boolean;
    wallet?: {
      name: string;
      id: string;
      type: WalletType;
    };
    card?: {
      name: string;
      type: CardTypeEnum;
      id: string;
      panMasked: string;
    };
  };
}

export const walletTypes = {
  [WalletType.ORGANIZATION]: 'Empresarial',
  [WalletType.PERSONAL]: 'Individual',
  [WalletType.SHARED]: 'Compartilhada',
};

const statementIcons: Record<TypePayment, string> = {
  [TypePayment.BANK_SLIP]: ICONS.barcode,
  [TypePayment.CREDIT_CARD]: ICONS['credit-card'],
  [TypePayment.PIX]: ICONS.pix,
  [TypePayment.P2P]: ICONS.exchange,
  [TypePayment.BILLING]: ICONS.billing,
  [TypePayment.FEE]: ICONS.fee,
};

export const useStatementColumns = (props?: { isGeneral?: boolean }) => {
  const { t } = useTranslation();
  const { openDrawer } = useDrawer();
  const queryClient = useQueryClient();

  const getTransactionType = (transaction: Transactions) => {
    if (transaction.boletoTransaction) {
      return TypePayment.BANK_SLIP;
    }
    if (transaction.pixTransaction) {
      return TypePayment.PIX;
    }
    if (transaction.cardTransaction) {
      return TypePayment.CREDIT_CARD;
    }
    if (transaction.billingTransaction) {
      return TypePayment.BILLING;
    }
    if (transaction.feeTransaction) {
      return TypePayment.FEE;
    }
    return TypePayment.P2P;
  };

  const getName = (transaction: TransactionsWithId) => {
    if (transaction.cardTransaction) {
      return transaction.cardTransaction.merchantName;
    }
    if (transaction.pixTransaction) {
      return pixTransaction(transaction);
    }
    if (transaction.boletoTransaction) {
      return bankSlipPayment(transaction);
    }
    if (transaction.billingTransaction) {
      return billingPayment(transaction);
    }
    if (transaction.feeTransaction) {
      return transaction.feeTransaction.description;
    }
    return 'Transferência interna';
  };

  const wallet: ColumnDef<TransactionsWithId>[] = props?.isGeneral
    ? [
        {
          accessorKey: 'wallet',
          header: t('general.wallet'),
          cell: ({ row }) => (
            <TableItemTitleSub
              title={row.original.additionalInfo?.wallet?.name}
              subtitle={
                walletTypes[
                  row.original.additionalInfo?.wallet?.type as WalletType
                ]
              }
              className={clsx({
                'line-through': row.original.financialImpactType === 'NONE',
              })}
            />
          ),
        },
      ]
    : [];

  const columns: ColumnDef<TransactionsWithId>[] = [
    {
      accessorKey: 'effectiveAt',
      header: t('general.date'),
      cell: ({ getValue, row }) => (
        <TableDate
          date={getValue<string>()}
          className={clsx({
            'line-through': row.original.financialImpactType === 'NONE',
          })}
        />
      ),
      meta: {
        width: 120,
        filter: {
          disabled: true,
        },
      },
    },
    {
      id: 'transactionType',
      header: '',
      cell: ({ row }) => {
        return (
          <TableIcon icon={statementIcons[getTransactionType(row.original)]} />
        );
      },
    },
    {
      id: 'transactionName',
      header: t('general.description'),
      cell: ({ row }) => {
        const panMasked =
          row.original?.additionalInfo?.card?.panMasked?.slice(-4);
        const transactionType = getTransactionType(row.original);
        const billing = row.original.billingTransaction ?? {
          initiationType: 'BILLING',
        };
        const typeString =
          transactionType === TypePayment.CREDIT_CARD
            ? `${t('general.card')} ${row.original.additionalInfo?.card?.name || ''} ${panMasked ? `(${panMasked})` : ''}`
            : transactionType === TypePayment.BILLING
              ? `Pagamento via ${typePaymentOptions[billing.initiationType]}`
              : '';

        const isScheduled =
          row.original.processingType === 'DELAYED' &&
          !!row.original?.internalErrorCode;
        const scheduleText = isScheduled
          ? ` - (${t('general.scheduling')})`
          : '';

        return (
          <TableItemTitleSub
            title={getName(row.original) + scheduleText}
            subtitle={typeString}
            className={clsx({
              'line-through': row.original.financialImpactType === 'NONE',
            })}
          />
        );
      },
      meta: {
        width: 'auto',
        filter: {
          disabled: true,
        },
      },
    },
    ...wallet,
    {
      accessorKey: 'type',
      header: t('general.origin'),
      cell: ({ row }) =>
        row.original?.additionalInfo?.isInternalTransaction
          ? t('general.internal')
          : t('general.external'),
    },
    {
      accessorKey: 'billingAmount',
      header: t('general.realized-amount'),
      cell: ({ row }) => {
        const isDebit = row.original.financialImpactType === 'DEBIT';
        const isNullTransaction = row.original.financialImpactType === 'NONE';

        const amount = isNullTransaction
          ? row.original.billingAmount
          : row.original.billingAmountAuthorized;

        return (
          <span
            className={clsx({
              'text-danger-500': isDebit,
              'line-through': isNullTransaction,
            })}
            style={{ font: 'inherit' }}
          >
            {`${isDebit ? '-' : ''}${calcCurrencyValue(amount, 'BRL')}`}
          </span>
        );
      },
      meta: {
        filter: {
          disabled: true,
        },
      },
    },
    {
      id: 'statement-actions',
      header: t('general.attachment'),
      cell: ({ row }) => {
        const isNullTransaction =
          row.original.financialImpactType === 'NONE' &&
          row?.original.processingType !== 'DELAYED';

        return (
          <Button
            variant="tertiary"
            size="small"
            className="w-8"
            disabled={isNullTransaction}
            data-testid="statement-attachment"
            onClick={() => {
              eventsHandler.openDrawerTransactionDetailsAdminWalletStatement();
              openDrawer(
                <DrawerTransactionDetails
                  openSidebar
                  transactionId={row.original._id}
                  walletId={row.original.walletId}
                  updateCountFiles={() =>
                    queryClient.invalidateQueries({
                      queryKey: transactionKeys.all(),
                    })
                  }
                />
              );
            }}
          >
            <i
              className={`${ICONS.paperclip} text-p1 ${
                isNullTransaction ? 'text-neutral-50' : 'text-neutral-100'
              }`}
            />
            <p
              className={`text-p5 ${
                isNullTransaction ? 'text-neutral-50' : 'text-neutral-100'
              }`}
            >
              {row.original.receiptsCount}
            </p>
          </Button>
        );
      },
    },
  ];

  return useFilterColumns(
    props?.isGeneral ? 'transactions-columns' : 'statements-columns',
    columns
  );
};
