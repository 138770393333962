import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ErrorMessage, Icon, Skeleton, Typography } from '..';
import { CustomSelectProps, Option } from './Select.interface';
import './Select.styles.scss';

export const Select = ({
  name,
  options,
  label,
  form,
  validationSchema,
  placeholder = '',
  onclick,
  children,
  defaultValue,
  dataTestId,
  search,
  searchPlaceholder,
  icon,
  isLoading = false,
  ...props
}: CustomSelectProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [filterValue, setFilterValue] = useState('');

  const optionsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  function normalizeString(value: string) {
    return value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^\w\s]/gi, '')
      .replaceAll(' ', '')
      .toLocaleLowerCase();
  }

  const filteredOptions = useMemo(() => {
    if (!search) {
      return options;
    } else {
      return options.filter((option) => {
        const normalizedOption = normalizeString(option.label);
        const normalizedFilter = normalizeString(filterValue);
        return normalizedOption.includes(normalizedFilter);
      });
    }
  }, [search, options, filterValue]);

  const { id, disabled } = props;

  const toggleOptions = async () => {
    if (search) {
      if (isOpen) {
        return;
      }

      if (!isOpen) {
        await setIsOpen(true);

        inputRef.current?.focus();
        return;
      }
    }

    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: Option) => {
    form.setValue(name, option?.value, { shouldDirty: true });
    form.trigger(name);
    const selectedRoles = filteredOptions.filter(
      (item) => item.label === option.label
    )[0];
    onclick && onclick(selectedRoles?.value);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      optionsRef.current &&
      !optionsRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const Options = () => (
    <div className="select_container_options">
      <div className="select_container_options_scroll">
        {filteredOptions.map((option) => (
          <div
            key={option.value}
            data-testid={`option-${option.value}`}
            className="option"
            onClick={() => handleOptionClick(option)}
          >
            {option.icon && (
              <Icon size="large">
                <i
                  id={`icon-${id}`}
                  data-testid={`option-icon-${option.value}`}
                  className={option.icon}
                />
              </Icon>
            )}
            <p className="text-p3">{option.label}</p>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <Controller
      name={name}
      control={form.control}
      defaultValue={defaultValue}
      rules={validationSchema}
      render={({ field, fieldState: { error } }) => {
        const selectedOption = options.find(
          (item) => item.value === field.value
        );

        return (
          <Skeleton height={62} isLoading={isLoading} className="mb-4">
            <div
              id={id}
              data-testid={id}
              className={`select ${disabled ? 'disabled' : ''}`}
              ref={optionsRef}
            >
              <select {...field} style={{ display: 'none' }}>
                {placeholder && <option>{placeholder}</option>}
                {options.map((option) => (
                  <option key={option.value} />
                ))}
              </select>
              <div className="select_container">
                <Typography
                  color="var(--product-neutral-n100)"
                  tag="p"
                  weight="p3"
                >
                  {label}
                </Typography>
              </div>
              <div
                className={clsx(
                  'select_trigger',
                  selectedOption
                    ? 'select_trigger--selected'
                    : 'select_trigger--no-selected',
                  {
                    open: isOpen,
                    invalid: error?.message,
                  }
                )}
                onClick={toggleOptions}
                data-testid={dataTestId ?? 'select-container'}
              >
                <input
                  ref={inputRef}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={{ display: search && isOpen ? 'block' : 'none' }}
                  id="input-filter"
                  type="text"
                  className="max-w-[90%] search-filter"
                  placeholder={searchPlaceholder || 'Pesquise aqui'}
                  value={filterValue}
                  onChange={(event) => setFilterValue(event.target.value)}
                />

                <div className="flex items-center h-4 gap-2">
                  {icon && <i className={`${icon} text-p3 w-[12px]`} />}

                  <p
                    className="text-p2"
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      display: search && isOpen ? 'none' : 'block',
                    }}
                  >
                    {selectedOption?.label || placeholder}
                  </p>
                </div>

                <i
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                  id="icon"
                  data-testid="select-icon"
                  className={`fa fa-caret-down fa-lg absolute top-1/2 right-4`}
                />
              </div>

              {isOpen && <Options />}

              {error?.message && (
                <ErrorMessage message={t(error?.message, '')} />
              )}
            </div>
          </Skeleton>
        );
      }}
    />
  );
};
