import 'dayjs/locale/pt-br';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';

import {
  Alert,
  ChangeStatusModal,
  DatePickerRangeQuery,
  HeaderCard,
  HeaderPage,
  MyWallet,
  SidebarFooter,
} from '@components';
import { lastTransactionsCompanyTHead } from '@constants/TransactionCompany';
import { emptyCards } from '@constants/emptyState';
import { ICONS } from '@constants/icons';
import { StatusEnum } from '@enums/Status.enum';
import { CardItem } from '@interfaces/Card.interfaces';
import {
  WalletCategory,
  WalletStatus,
  WalletType,
} from '@interfaces/Wallets.interfaces';
import { customerEvents, eventsHandler } from '@lib';
import { DrawerNewBankSlip, DrawerPixArea } from '@modules/drawer';
import { TransactionsModule } from '@modules/transactions';
import {
  Button,
  Card,
  Column,
  Dialog,
  DropDownList,
  EmptyState,
  Icon,
  PaymentMethod,
  Row,
  Sidebar,
  Skeleton,
  SlideShow,
  Toastr,
  Typography,
} from '@portao3-web/ui';
import { UserContext, useDrawer, useUser } from '@providers';
import { useCardListQuery } from '@services/cards/hooks';
import {
  useClearWalletBalanceMutation,
  useDeactivateWalletMutation,
  useWalletQuery,
} from '@services/wallet/hooks';
import { capitalizeFirstLetter } from '@utils/CapitalizeFirstLetter';
import { walletPermission } from '@utils/walletPermission';
import '../../Wallets.styles.scss';
import { WalletCardDetails } from '../../components/walletCardDetails';
import {
  textDeactivateWallet,
  textResetBalance,
} from '../../constants/wallets';

export const SelectedWallet = () => {
  const organizationId = localStorage.getItem('organization') ?? '';

  const { walletId, walletPage } = useParams();
  const actionsMenuRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const { openDrawer } = useDrawer();
  const { isAdmin } = useUser();
  const user = useContext(UserContext);

  const [closeSideBarPayment, setCloseSideBarPayment] = useState(false);
  const [openSideBarPayment, setOpenSideBarPayment] = useState(false);
  const [newPaymentTicket, setNewPaymentTicket] = useState(false);
  const [selectCategory, setSelectCategory] = useState<boolean>(false);
  const [categoryType, setCategoryType] = useState<WalletCategory>(
    {} as WalletCategory
  );
  const [openActionModal, setOpenActionModal] = useState({
    open: false,
    action: '',
  });
  const [showActionOptions, setShowActionOptions] = useState(false);

  const { data: wallet, fetchStatus } = useWalletQuery({
    organizationId,
    walletId: walletId ?? '',
  });

  const { data: cardWalletUserList, isLoading: isCardWalletLoading } =
    useCardListQuery({ walletId, status: StatusEnum.ACTIVE });

  const titleSideSheet = () => {
    if (newPaymentTicket) {
      return {
        title: `Pagamento do boleto`,
        icon: ICONS.barcode,
      };
    }

    return {
      title: `Novo pagamento`,
      icon: ICONS.barcode,
    };
  };

  const openBankSlipPayment = () => {
    setOpenSideBarPayment(true);
    setSelectCategory(true);
  };

  const setCloseAllStates = () => {
    setOpenSideBarPayment(false);
    setCloseSideBarPayment(false);
    setNewPaymentTicket(false);
  };

  const backNewPayments = () => {
    setNewPaymentTicket(false);
    setOpenSideBarPayment(false);
  };

  const selectedCategory = (categoryType: WalletCategory) => {
    setCategoryType(categoryType);
    if (categoryType.amount === 0) {
      return;
    }

    setSelectCategory(false);
    setNewPaymentTicket(true);
  };

  const getNamePage = () => {
    switch (walletPage?.toLowerCase()) {
      case 'statement':
        return 'Extrato';
      case 'cards':
        return 'Cartões';
      default:
        return 'Visão Geral';
    }
  };

  const dropDownActionsList = [
    {
      label: 'Editar',
      action: () => {
        eventsHandler.selectOptionEditWalletWallet();
        openDrawer('edit-wallet', { walletId: wallet?.id ?? '' });
        setShowActionOptions(false);
      },
    },
    {
      label: 'Desativar',
      action: () => {
        eventsHandler.selectOptionDeactivateWalletWallet();
        setOpenActionModal({ open: true, action: 'DEACTIVATE' });
      },
    },
    {
      label: 'Zerar saldo',
      action: () => {
        eventsHandler.selectOptionResetWalletBalanceWallet();
        setOpenActionModal({ open: true, action: 'RESET' });
      },
    },
  ];

  const {
    mutateAsync: clearBalance,
    isPending: isClearingBalance,
    error: clearBalanceError,
  } = useClearWalletBalanceMutation();

  const {
    mutateAsync: blockWallet,
    isPending: isBlockingWallet,
    error: blockWalletError,
  } = useDeactivateWalletMutation();

  const handleChangeWalletState = () => {
    if (openActionModal.action === 'RESET') {
      eventsHandler.submitModalResetWalletBalanceWallet();
      clearBalance(walletId ?? '', {
        onSuccess: () => {
          Toastr.success('Carteira zerada com sucesso!');

          customerEvents.walletBalanceUpdated({
            amount: 0,
            id: walletId || '',
            type: null,
          });

          setOpenActionModal({ open: false, action: '' });
        },
      });
    }

    if (openActionModal.action === 'DEACTIVATE') {
      blockWallet(walletId ?? '', {
        onSuccess: () => {
          Toastr.success('Carteira desativada com sucesso!');
          setOpenActionModal({ open: false, action: '' });

          customerEvents.walletDeleted({
            id: wallet?.id ?? '',
            name: wallet?.name ?? '',
          });
        },
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      showActionOptions &&
        actionsMenuRef.current &&
        !actionsMenuRef.current.contains(event.target as Node) &&
        setShowActionOptions(!showActionOptions);
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showActionOptions]);

  const bankSlipOption =
    wallet?.settings?.payment?.bankSlip &&
    wallet?.status !== WalletStatus.DEACTIVATED
      ? [
          {
            label: t('company.actions.bank-slip-payment'),
            action: () => {
              eventsHandler.selectOptionFundsOutBoletoWallet();
              openBankSlipPayment();
            },
          },
        ]
      : [];

  return (
    <div className="wallet">
      <HeaderPage
        title="Carteiras"
        breadcrumbList={[
          {
            label: 'Carteiras',
            url: '/wallets',
            isDisabled: false,
          },
          {
            label: capitalizeFirstLetter(wallet?.name?.replace('-', ' ') || ''),
            url: `/wallets/${walletId}/${wallet?.name
              ?.toLowerCase()
              .replaceAll(' ', '-')}`,
            isDisabled: true,
          },
          {
            label: `${capitalizeFirstLetter(getNamePage())}`,
            url: `/wallets/${walletId}/${wallet?.name
              ?.toLowerCase()
              .replaceAll(' ', '-')}/${walletPage}`,
            isDisabled: true,
          },
        ]}
        isLoading={fetchStatus === 'fetching'}
      >
        <DatePickerRangeQuery />

        <Dialog variant="popover">
          <Dialog.Trigger>
            <Button
              size="large"
              variant="primary"
              data-testid="newPayment"
              onClick={() => eventsHandler.viewOptionsWalletTransactionWallet()}
            >
              {t('button.actions')}
              <i className="fa-solid fa-chevron-down fa-1x" />
            </Button>
          </Dialog.Trigger>
          <Dialog.Content>
            <DropDownList
              list={[
                {
                  label: t('company.actions.pix-area'),
                  action: () => {
                    eventsHandler.selectOptionAreaPixWallet();
                    walletId &&
                      openDrawer(<DrawerPixArea walletId={walletId} />);
                  },
                },
                ...bankSlipOption,
                {
                  label: t('general.funds-in'),
                  action: () => {
                    eventsHandler.selectOptionFundsInWallet();
                    walletId && openDrawer('funds-in', { walletId });
                  },
                },
              ]}
            />
          </Dialog.Content>
        </Dialog>
      </HeaderPage>

      <div className="wallet_content">
        <Row>
          <Column lg={6}>
            <Card className="wallets">
              <HeaderCard title={t('wallet.header-card.title')}>
                <div className="card_header_elements">
                  {(isAdmin || (wallet && walletPermission(wallet, user))) && (
                    <Button
                      data-testid="buttonAddBalance"
                      size="medium"
                      variant="tertiary"
                      className="card_header_elements card_header_elements_new_balance"
                      disabled={wallet?.status === WalletStatus.DEACTIVATED}
                      onClick={() => {
                        eventsHandler.clickButtonManageWalletWallet();
                        openDrawer('wallet-balance', {
                          walletId: walletId ?? '',
                        });
                      }}
                    >
                      <Typography tag="p" color="var(--product-neutral-n100)">
                        Gerenciar saldo
                      </Typography>
                      <i className="fa-regular fa-plus-circle icon" />
                    </Button>
                  )}
                  {(isAdmin || (wallet && walletPermission(wallet, user))) && (
                    <div ref={actionsMenuRef}>
                      <Button
                        data-testid="buttonSelectActions"
                        size="medium"
                        variant="tertiary"
                        className="wallet_button_finder"
                        disabled={wallet?.status === WalletStatus.DEACTIVATED}
                        onClick={() => {
                          !showActionOptions &&
                            eventsHandler.viewOptionsWalletConfigWallet();
                          setShowActionOptions(!showActionOptions);
                        }}
                      >
                        <Icon size="xlarge">
                          <i className="fa-regular fa-ellipsis-vertical" />
                        </Icon>
                      </Button>
                      {showActionOptions && (
                        <DropDownList
                          list={
                            wallet?.type !== WalletType.PERSONAL
                              ? dropDownActionsList
                              : dropDownActionsList.filter(
                                  (elem) => elem.label !== 'Desativar'
                                )
                          }
                        />
                      )}
                    </div>
                  )}
                </div>
              </HeaderCard>
              <div className="payments_card">
                <MyWallet
                  walletCategoryList={wallet?.balances}
                  totalBalance={wallet?.totalBalance}
                  isClickable={false}
                  isFetching={fetchStatus === 'fetching'}
                />
              </div>
            </Card>
          </Column>
          <Column lg={6}>
            <Card className="payments">
              <div className="payments_methods">
                <PaymentMethod
                  label={t('general.pix')}
                  onClick={() => {
                    eventsHandler.clickButtonAreaPixWallet();
                    walletId &&
                      openDrawer(<DrawerPixArea walletId={walletId} />);
                  }}
                  disabled={
                    !wallet?.settings?.payment?.pix ||
                    Boolean(!wallet?.id) ||
                    wallet.status === WalletStatus.DEACTIVATED
                  }
                >
                  <i className="fa-brands fa-pix newPayments_icon-pix"></i>
                </PaymentMethod>
                <PaymentMethod
                  label={t('general.pay-bank-slip')}
                  onClick={() => {
                    eventsHandler.clickButtonFundsOutBoletoWallet();
                    openBankSlipPayment();
                  }}
                  disabled={
                    !wallet?.settings?.payment?.bankSlip ||
                    Boolean(!wallet?.id) ||
                    wallet?.status === WalletStatus.DEACTIVATED
                  }
                >
                  <i className="fa-regular fa-barcode newPayments_icon-pix"></i>
                </PaymentMethod>
                <PaymentMethod
                  label={t('general.funds-in')}
                  onClick={() => {
                    eventsHandler.clickButtonFundsInWallet();
                    walletId && openDrawer('funds-in', { walletId });
                  }}
                  disabled={wallet?.status === WalletStatus.DEACTIVATED}
                >
                  <i className="fa-regular fa-rectangle-history-circle-plus newPayments_icon-pix" />
                </PaymentMethod>
              </div>

              <div className="payments_card">
                <div className="payments_card_title">
                  <Typography tag="h4" color="var(--product-neutral-n800)">
                    Principais cartões
                  </Typography>
                  <div className="payments_card_new-card_wrapper">
                    <Button
                      type="button"
                      size="small"
                      variant="tertiary"
                      className="payments_card_new-card_button"
                      onClick={() => {
                        eventsHandler.createButtonDrawerNewCardWallet();
                        openDrawer('create-card', { walletId });
                      }}
                      disabled={wallet?.status === WalletStatus.DEACTIVATED}
                    >
                      <Typography
                        tag="p"
                        weight="p3"
                        color="var(--product-neutral-n100)"
                      >
                        Novo cartão
                      </Typography>
                      <i className="fa-regular fa-plus icon" />
                    </Button>
                    <Skeleton width={'5%'} isLoading={isCardWalletLoading}>
                      <NavLink
                        to={`/wallets/${walletId}/cards`}
                        onClick={() =>
                          eventsHandler.clickButtonViewCardsListWallet()
                        }
                      >
                        <Typography
                          tag="p"
                          weight="p3"
                          color="var(--product-neutral-n100)"
                        >
                          Ver todos
                        </Typography>
                      </NavLink>
                    </Skeleton>
                  </div>
                </div>
                <Skeleton height={'150px'} isLoading={isCardWalletLoading}>
                  <SlideShow>
                    {cardWalletUserList?.items
                      .slice(0, 3)
                      .map((item: CardItem, index: number) => {
                        return (
                          <WalletCardDetails
                            cardNumber={item.panMasked.slice(-4)}
                            cardId={item.id}
                            cardType={item.type}
                            cardName={item.name}
                            key={index}
                            walletId={wallet?.id ?? ''}
                            trackUserAction={{
                              show: () =>
                                eventsHandler.showButtonCardDataWallet(),
                              hide: () =>
                                eventsHandler.hideButtonCardDataWallet(),
                            }}
                          />
                        );
                      })}
                  </SlideShow>
                </Skeleton>

                {!isCardWalletLoading &&
                  cardWalletUserList?.items.length === 0 && (
                    <EmptyState
                      direction="row"
                      title={emptyCards.title}
                      subtitle={emptyCards.subtitle}
                      image={emptyCards.image}
                    />
                  )}
              </div>
            </Card>
          </Column>
        </Row>
        <Row>
          <Column lg={12}>
            {walletId && (
              <Card>
                <HeaderCard title="Últimas transações"></HeaderCard>
                <TransactionsModule
                  thead={lastTransactionsCompanyTHead}
                  endpoint={`/wallets/${walletId}`}
                  key={walletId}
                />
              </Card>
            )}
          </Column>
        </Row>
      </div>

      {openSideBarPayment && (
        <Sidebar
          title={titleSideSheet()?.title}
          icon={titleSideSheet()?.icon}
          open={openSideBarPayment}
          hasModal={closeSideBarPayment}
          onClose={setCloseAllStates}
        >
          {categoryType.amount === 0 && (
            <Alert status="error">
              <Typography tag="p" weight="p2" color="var(--product-error-d500)">
                Você não possui saldo para fazer a transação!
              </Typography>
            </Alert>
          )}

          {selectCategory && (
            <div className="sidebar_myWallet">
              <MyWallet
                walletCategoryList={wallet?.balances ?? []}
                totalBalance={wallet?.totalBalance ?? 0}
                setSelectedCategory={(element) => selectedCategory(element)}
                isClickable={true}
              />

              <SidebarFooter>
                <Button
                  onClick={backNewPayments}
                  type="button"
                  size="large"
                  variant="primary"
                >
                  {t('general.button.close')}
                </Button>
              </SidebarFooter>
            </div>
          )}

          {newPaymentTicket && (
            <DrawerNewBankSlip
              onClose={backNewPayments}
              walletId={wallet?.id}
              categoryType={categoryType.category}
            />
          )}
        </Sidebar>
      )}

      {openActionModal.open && wallet && (
        <ChangeStatusModal
          open={openActionModal.open}
          closeModal={() => {
            eventsHandler.cancelModalResetWalletBalanceWallet();
            setOpenActionModal({ open: false, action: '' });
          }}
          changeStatus={handleChangeWalletState}
          modalText={
            openActionModal.action === 'RESET'
              ? textResetBalance
              : {
                  modalTitle: `Deseja desativar a carteira: ${capitalizeFirstLetter(
                    wallet.name
                  )}?`,
                  ...textDeactivateWallet,
                }
          }
          errorService={!!clearBalanceError || !!blockWalletError}
          isLoading={isClearingBalance || isBlockingWallet}
        />
      )}
    </div>
  );
};
