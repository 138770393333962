import { regex } from '@constants/regex';
import { PixDictType, PixInitiationType } from '@enums/newPix.enum';
import { ErrorRequest } from '@interfaces/ErrorRequest.interfaces';
import { customerEvents } from '@lib/customerIo';
import { Button, ErrorMessage, Icon, Input, Typography } from '@portao3-web/ui';
import { cnpjValidate, cpfValidate } from '@utils/cpfValidate';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { cnpjMask, cpfMask, moneyMask, phoneDDIMask } from '@utils/formatMasks';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert, ContextualError, ContextualErrorType } from '../../alerts';
import { SidebarFooter } from '../../sidebarFooter';
import { SidebarSection } from '../../sidebarSection';
import { FormPayment, NewPixProps } from '../NewPix.interface';
import { pixPayment } from '../services/PixService';

export const ConfigPix = ({
  goNextStep,
  back,
  confirmPayment,
  walletId,
  setDictType,
  balance,
  setInitiatedValues,
}: NewPixProps & {
  setInitiatedValues: (value: FormPayment) => void;
  setDictType: (value: string) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [messageErrorService, setMessageErrorService] =
    useState<ContextualErrorType>(null);
  const { t } = useTranslation();

  const form = useForm<FormPayment>({
    defaultValues: {
      dict: '',
      key: '',
      amount: moneyMask('0'),
      description: '',
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    clearErrors,
    getValues,
    setError,
    formState: { errors, isDirty },
  } = form;

  const formatDictKey = (formData: FormPayment) => {
    switch (getValues('dict')) {
      case PixDictType.CPF:
        return formData.key.replace(/\D/g, '');
      case PixDictType.CNPJ:
        return formData.key.replace(/\D/g, '');
      case PixDictType.EVP:
        return formData.key;
      case PixDictType.EMAIL:
        return formData.key.toLowerCase();
      case PixDictType.PHONE: {
        const hasDDI = formData.key.startsWith('+55')
          ? formData.key
          : `+55${formData.key}`;
        return hasDDI;
      }
      default:
        return '';
    }
  };

  const submitFormPix = async (formData: FormPayment) => {
    setLoading(!loading);
    setMessageErrorService(null);

    const form = {
      ...formData,
      amount: formData.amount.replace(/\D/g, ''),
      initiationType: PixInitiationType.DICT,
      key: formatDictKey(formData),
      dict: getValues('dict'),
    };

    try {
      const responsePix = await pixPayment(form, walletId);
      customerEvents.pixInitiated({
        type: PixInitiationType.DICT,
        amount: +form.amount,
        dictType: getValues('dict'),
        walletId: responsePix.walletId,
      });

      setInitiatedValues(form);
      setDictType(getValues('dict'));
      confirmPayment(responsePix);
      setLoading(false);
      goNextStep();
    } catch (error: unknown) {
      setLoading(false);

      const errorService = error as AxiosError;
      const errorInfo = errorService?.response?.data as ErrorRequest;

      setMessageErrorService({
        message: t(`error.CODE_ERROR.${errorInfo?.code}`, t('error.default')),
        traceId: errorInfo?.traceId || '',
      });
    }
  };

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      setMessageErrorService(null);

      const amount = value.amount?.replace(/\D/g, '');
      if (Number(amount) <= balance) {
        clearErrors('amount');
      }

      if (getValues('dict') === PixDictType.CPF && type === 'change') {
        const cpf = cpfMask(value.key || '');
        setValue('key', cpf);
      }

      if (getValues('dict') === PixDictType.CNPJ && type === 'change') {
        const cnpj = cnpjMask(value.key || '');
        setValue('key', cnpj);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, getValues, clearErrors, balance]);

  const handleClearFields = () => {
    setMessageErrorService(null);
    reset();
  };

  const getKeyType = (value: string) => {
    if (value.length === 0) return 'unknown';

    const CPF_WITH_MASK_LENGTH = 15;
    const CPF_NUMBER_LENGTH = 11;
    const CNPJ_WITH_MASK_LENGTH = 19;
    const CNPJ_NUMBER_LENGTH = 14;

    const numericValue = value.replace(/\D/g, '');
    const ddd = numericValue.slice(0, 2);
    const isPhone =
      /^([1-9][0-9])$/.test(ddd) &&
      numericValue[2] === '9' &&
      numericValue.length === 11;

    if (value.includes('@')) return PixDictType.EMAIL;

    if (
      numericValue.length === CPF_NUMBER_LENGTH &&
      value.length <= CPF_WITH_MASK_LENGTH
    ) {
      if (!cpfValidate(cpfMask(value))) {
        return PixDictType.CPF;
      }
    }

    if (isPhone) return PixDictType.PHONE;

    if (
      numericValue.length === CNPJ_NUMBER_LENGTH &&
      value.length <= CNPJ_WITH_MASK_LENGTH
    ) {
      return PixDictType.CNPJ;
    }

    return PixDictType.EVP;
  };

  const validateKey = (value: string) => {
    const keyType = getKeyType(value);

    setValue('dict', keyType);

    switch (keyType) {
      case PixDictType.CPF: {
        const cpf = cpfMask(value.replace(/\D/g, '') || '');
        setValue('key', cpf);
        if (cpf.length !== 14) {
          return;
        }
        return !cpfValidate(cpf)
          ? clearErrors('key')
          : setError('key', {
              message: 'CPF inválido',
            });
      }

      case PixDictType.CNPJ: {
        const cnpj = cnpjMask(value.replace(/\D/g, '') || '');
        setValue('key', cnpj);

        return cnpjValidate(cnpj)
          ? setError('key', { message: 'CNPJ inválido' })
          : clearErrors('key');
      }

      case PixDictType.EMAIL:
        return regex.email.test(value)
          ? clearErrors('key')
          : setError('key', { message: 'E-mail inválido' });

      case PixDictType.PHONE: {
        const phone = phoneDDIMask(value);
        setValue('key', phone);
        return;
      }

      default:
        return clearErrors('key');
    }
  };

  return (
    <form onSubmit={handleSubmit(submitFormPix)} data-testid="new-pix">
      <ContextualError error={messageErrorService} />
      <SidebarSection
        numberSection={1}
        titleSection="Para quem deseja transferir?"
        subTitleSection=""
      >
        <Input
          type="text"
          label="Chave PIX"
          placeholder="Ex: CPF, CNPJ, e-mail, telefone ou chave aleatória"
          id="key"
          name="key"
          register={register}
          data-testid="key"
          error={!!errors['key']}
          onChange={(event) => {
            const value = event.target.value;
            validateKey(value);
          }}
          validationSchema={{
            required: 'O campo é obrigatório',
          }}
        >
          {errors['key']?.message && (
            <ErrorMessage message={errors['key'].message.toString()} />
          )}
        </Input>

        <Alert status="info">
          <p className="text-p3 text-neutral-100">
            Você pode informar qualquer chave entre:
          </p>
          <p className="text-p3 text-info-500">
            Celular, CPF, CNPJ, E-mail ou Chave aleatória
          </p>
        </Alert>
      </SidebarSection>

      <SidebarSection
        numberSection={2}
        titleSection="Forneça os detalhes do Pix"
        subTitleSection=""
      >
        <div className="balance-card">
          <div className="flex items-center">
            <Icon size="xlarge">
              <i
                className="fa-regular fa-wallet"
                style={{ color: 'var(--product-neutral-n100)' }}
              />
            </Icon>
            <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
              Saldo disponível
            </Typography>
          </div>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {calcCurrencyValue(balance)}
          </Typography>
        </div>

        <Input
          type="tel"
          placeholder="Inserir valor"
          label="Valor"
          id="amount"
          name="amount"
          data-testid="amount"
          register={register}
          error={!!errors['amount']}
          onChange={(event) => {
            const value = moneyMask(event.target.value);
            setValue('amount', value, { shouldDirty: true });
          }}
          disabled={loading}
          validationSchema={{
            validate: (value: string) => {
              if (/^0*$/.test(value.replace(/\D/g, ''))) {
                return 'O valor é obrigatório';
              }
            },
          }}
          maxLength={17}
        >
          {errors['amount']?.message && (
            <ErrorMessage message={errors['amount'].message.toString()} />
          )}
        </Input>
      </SidebarSection>

      <SidebarFooter>
        {isDirty ? (
          <Button
            onClick={handleClearFields}
            variant="tertiary"
            size="large"
            type="reset"
            data-testid="resetForm"
            disabled={loading}
          >
            Limpar campos
            <Icon size="large">
              <i className="fa-regular fa-trash" />
            </Icon>
          </Button>
        ) : (
          <Button type="button" variant="tertiary" size="large" onClick={back}>
            Voltar
          </Button>
        )}
        <Button
          type="submit"
          size="large"
          data-testid="submitForm"
          className="button_submit"
          isLoading={loading}
          disabled={watch('amount') === moneyMask('0')}
        >
          Continuar
        </Button>
      </SidebarFooter>
    </form>
  );
};
