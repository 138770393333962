import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SidebarFooter } from '@components';
import { ICONS } from '@constants/icons';
import { WalletStatus } from '@interfaces/Wallets.interfaces';
import { customerEvents, eventsHandler } from '@lib';
import { Button, PaymentMethod, Sidebar } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useWalletQuery } from '@services/wallet/hooks';
import { useLocation } from 'react-router-dom';
import { DrawerNewPix } from '../drawerNewPix';
import {
  DrawerPixAreaProps,
  DrawerPixAreaType,
} from './DrawerPixArea.interfaces';
import { ManagePixKey } from './components';

export const DrawerPixArea = ({ area, walletId }: DrawerPixAreaProps) => {
  const organizationId = localStorage.getItem('organization') ?? '';

  const { t } = useTranslation();
  const { closeDrawer, openDrawer } = useDrawer();
  const { pathname } = useLocation();

  const [flow, setFlow] = useState<DrawerPixAreaType | null>(area ?? null);

  const { data: wallet } = useWalletQuery({ walletId, organizationId });

  const flows = {
    [DrawerPixAreaType.PIX]: (
      <DrawerNewPix
        onBack={() => {
          setFlow(null);
        }}
        walletId={walletId}
      />
    ),
    [DrawerPixAreaType.FUNDS_IN]: (
      <ManagePixKey onBack={() => setFlow(null)} walletId={walletId} />
    ),
    [DrawerPixAreaType.KEYS]: (
      <ManagePixKey onBack={() => setFlow(null)} walletId={walletId} />
    ),
  };

  const sidebarTitle = {
    [DrawerPixAreaType.PIX]: t('general.new-payment'),
    [DrawerPixAreaType.FUNDS_IN]: t('pix.drawer.key.header'),
    [DrawerPixAreaType.KEYS]: t('pix.drawer.key.header'),
  };

  const handleClose = () => {
    pathname.includes('/company') &&
      eventsHandler.closeButtonAreaPixDrawerWalletCompany?.();
    pathname.includes('/wallets') &&
      eventsHandler.closeButtonAreaPixDrawerWallet?.();

    closeDrawer();
  };

  return (
    <Sidebar
      title={flow ? sidebarTitle[flow] : t('company.actions.pix-area')}
      icon={ICONS.qrcode}
      open={true}
      onClose={handleClose}
    >
      {flow ? (
        flows[flow]
      ) : (
        <div className="flex flex-row gap-4">
          <PaymentMethod
            label={t('general.transfer')}
            data-testid="drawer-pix-transfer"
            onClick={() => {
              pathname.includes('/company') &&
                eventsHandler.clickButtonAreaPixTransferWalletCompany?.();
              pathname.includes('/wallets') &&
                eventsHandler.clickButtonAreaPixTransferWallet?.();

              setFlow(DrawerPixAreaType.PIX);
            }}
            disabled={
              walletId === 'default'
                ? false
                : !wallet?.settings?.payment?.pix ||
                  Boolean(!wallet?.id) ||
                  wallet.status === WalletStatus.DEACTIVATED
            }
          >
            <i className="fa-regular fa-money-bill-transfer fa-1x newPayments_icon-pix" />
          </PaymentMethod>
          <PaymentMethod
            label={t('general.funds-in')}
            onClick={() => {
              pathname.includes('/company') &&
                eventsHandler.clickButtonAreaPixFundsInWalletCompany?.();
              pathname.includes('/wallets') &&
                eventsHandler.clickButtonAreaPixFundsInWallet?.();

              setFlow(DrawerPixAreaType.FUNDS_IN);
            }}
          >
            <i className="fa-regular fa-grid-round-2-plus fa-1x newPayments_icon-pix" />
          </PaymentMethod>
          <PaymentMethod
            label={t('general.keys')}
            onClick={() => {
              pathname.includes('/company') &&
                eventsHandler.clickButtonAreaPixKeysWalletCompany?.();
              pathname.includes('/wallets') &&
                eventsHandler.clickButtonAreaPixKeysWallet?.();

              customerEvents.pixKeysViewed({
                walletId,
              });
              setFlow(DrawerPixAreaType.KEYS);
            }}
          >
            <i className="fa-regular fa-key fa-1x newPayments_icon-pix" />
          </PaymentMethod>
          <PaymentMethod
            label={t('general.batch')}
            onClick={() => openDrawer('batch-payment')}
          >
            <i className="fa-regular fa-layer-group fa-1x newPayments_icon-pix" />
          </PaymentMethod>
        </div>
      )}
      {!flow && (
        <SidebarFooter>
          <Button
            type="button"
            size="large"
            variant="tertiary"
            data-testid="button-close-drawer"
            onClick={() => {
              pathname.includes('/company') &&
                eventsHandler.closeButtonAreaPixDrawerWalletCompany?.();
              pathname.includes('/wallets') &&
                eventsHandler.closeButtonAreaPixDrawerWallet?.();

              closeDrawer();
            }}
          >
            {t('button.back')}
          </Button>
          <Button type="button" size="large" variant="primary" disabled>
            {t('button.finish')}
          </Button>
        </SidebarFooter>
      )}
    </Sidebar>
  );
};
