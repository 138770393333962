import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  CUSTOM_FIELDS_MAPPER,
  ContextualError,
  ContextualErrorType,
  InDrawerAuthentication,
  SidebarFooter,
  SidebarSection,
} from '@components';

import { ICONS } from '@constants/icons';
import { WalletBalanceCategory } from '@enums/WalletEnum';
import { useAuthentication } from '@hooks';
import {
  CustomFieldLocation,
  CustomFieldType,
} from '@interfaces/CustomFields.interfaces';
import { ErrorRequest } from '@interfaces/ErrorRequest.interfaces';
import { customerEvents } from '@lib';
import { ValueWrapper } from '@modules/drawer/drawerFundsIn/components/ValueWrapper';
import {
  Button,
  Column,
  DatePicker,
  Row,
  Select,
  Skeleton,
  Typography,
} from '@portao3-web/ui';
import { useCustomFieldsQuery } from '@services/customFields/hooks';
import { moneyMask } from '@utils/formatMasks';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { FormPayment } from '../newPix/NewPix.interface';
import { ValueCardComponent } from './components/ValueCardComponent';
import { useBankSlipPaymentConfirmationMutation } from './hooks/useBankSlipPaymentConfirmationMutation';
import { cancelBankSlipService } from './services/PaymentConfirmationService';

import { BankSlip } from '@interfaces/BankSlip.interface';
import { CreateTransactionSchedule } from '@interfaces/Schedule.interfaces';
import { schedulingTransactionKeys } from '@services/scheduling';
import './PaymentConfirmation.styles.scss';

interface PaymentConfirmationBankSlipProps {
  onConfirm: (isScheduling: boolean) => void;
  back: () => void;
  paymentConfirm: BankSlip;
  paymentVoucher: (value: BankSlip | null | CreateTransactionSchedule) => void;
  walletId?: string;
  categoryType?: string;
}

export const PaymentConfirmationBankSlip = ({
  back,
  onConfirm,
  paymentConfirm,
  paymentVoucher,
  walletId,
  categoryType,
}: PaymentConfirmationBankSlipProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [loadingCancelPayment, setLoadingCancelPayment] = useState(false);
  const [contextualError, setContextualError] =
    useState<ContextualErrorType | null>(null);
  const [isEditingValuePayment, setIsEditingValuePayment] = useState(false);
  const [hasEditedAmount, setHasEditedAmount] = useState(false);

  const {
    controller,
    authSubmit,
    pin,
    onAuthError,
    onAuthSuccess,
    isAuthentication,
  } = useAuthentication();

  const {
    mutate: confirmBankSlipPaymentMutate,
    isPending: isPendingBankSlipConfirmation,
  } = useBankSlipPaymentConfirmationMutation();

  const {
    data: customFields,
    error: customFieldsError,
    isLoading: isLoadingCustomFields,
  } = useCustomFieldsQuery({
    organization: localStorage.getItem('organization'),
    location: CustomFieldLocation.TRANSACTION,
  });

  const form = useForm<FormPayment>({
    mode: 'onBlur',
    defaultValues: {
      amount: moneyMask(paymentConfirm?.txnUpdatedAmount),
      processingDate: dayjs()
        .startOf('day')
        .toDate()
        .toISOString() as unknown as Date,
    },
  });

  const { handleSubmit } = form;

  const isAfterDueDate = dayjs()
    .startOf('day')
    .isAfter(dayjs(paymentConfirm?.dueDate).utc(false).startOf('day'));

  const isPaymentToday =
    dayjs(form.watch('processingDate')).utc(false).format('YYYY-MM-DD') ===
    dayjs().format('YYYY-MM-DD');

  const isExpired = dayjs().utc(true).isAfter(dayjs(paymentConfirm.expiresAt));

  const isAmountEditable =
    paymentConfirm?.txnMaxAmount !== paymentConfirm?.txnMinAmount ||
    paymentConfirm?.txnMaxAmount === 0;

  const bankSlipPayment = async (
    formData: FormPayment,
    confirmationPaymentId: string
  ) => {
    const formattedCustomFields = formData.customFields
      ? Object.entries(formData.customFields).map(([key, value]) => {
          const field = customFields?.items?.find(
            (field) => field.identifier === key
          );

          const getMultiSelectValue = () => {
            if (value.length < 1) return [];
            if (typeof value === 'string') return [value];
            return value;
          };

          const getFieldValues = () => {
            const result = value;
            if (field?.type === CustomFieldType.NUMBER) {
              return [value.replace(/\D/g, '')];
            }
            return result ? [result] : [];
          };

          return {
            id: field?.id ?? '',
            label: field?.label ?? '',
            values:
              field?.type === CustomFieldType.MULTI_SELECT
                ? getMultiSelectValue()
                : getFieldValues(),
            identifier: key,
            version: field?.version ?? 1,
          };
        })
      : [];

    const payload = {
      amount: Number(form.watch('amount').replace(/\D/g, '')),
      category: categoryType || WalletBalanceCategory.FLEX_INTERNATIONAL,
      id: confirmationPaymentId,
      customFields: formattedCustomFields,
      processingDate: dayjs(formData.processingDate)
        .utc(false)
        .format('YYYY-MM-DD'),
      description: paymentConfirm?.payee || '-',
    };

    confirmBankSlipPaymentMutate(
      { payload, walletId, pin: pin || undefined },
      {
        onSuccess: (response) => {
          onAuthSuccess();

          if (isPaymentToday && !('scheduledAt' in response)) {
            customerEvents.boletoConfirmed({
              txnOriginalAmount: response.txnOriginalAmount,
              dueDate: response.dueDate,
              txnUpdatedAmount: response.confirmedAmount as number,
              walletId: response.walletId,
            });
          }

          if (!isPaymentToday && 'scheduledAt' in response) {
            customerEvents.boletoScheduleConfirmed({
              originalAmount: paymentConfirm.txnOriginalAmount,
              dueDate: paymentConfirm.dueDate,
              updatedAmount: response?.amount,
              walletId: response?.wallet,
              scheduleId: response?.id,
              scheduleDate: response?.scheduledAt,
            });
          }

          paymentVoucher(response);
          refetchWalletData();
          onConfirm(!isPaymentToday);

          queryClient.invalidateQueries({
            queryKey: schedulingTransactionKeys.lists(),
          });
        },
        onError: (error) => {
          const isAuthError = onAuthError(error);
          if (isAuthError) return;

          setContextualError({
            message: t(
              `error.CODE_ERROR.${error?.response?.data?.code}`,
              t('error.default')
            ),
            traceId: error?.response?.data?.traceId,
          });
        },
      }
    );
  };

  const submitForm = async (formData: FormPayment) => {
    setContextualError(null);

    bankSlipPayment(formData, paymentConfirm?._id);
  };

  const cancelPayment = async () => {
    setLoadingCancelPayment(true);

    try {
      await cancelBankSlipService(paymentConfirm?._id, walletId);
      setLoadingCancelPayment(false);
      back();
    } catch (error) {
      const errorService = error as AxiosError;
      const errorInfo = errorService.response?.data as ErrorRequest;

      setLoadingCancelPayment(false);
      setContextualError({
        message: t(`error.CODE_ERROR.${errorInfo?.code}`, t('error.default')),
        traceId: errorInfo?.traceId,
      });
    }
  };

  const renderCustomFields = () => {
    return customFieldsError ? (
      <Alert status="error">
        <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
          {t('error.loadCustomFields')}
        </Typography>
      </Alert>
    ) : (
      customFields?.items
        ?.slice()
        ?.reverse()
        ?.map((field) => {
          const Component =
            CUSTOM_FIELDS_MAPPER[
              field.type as keyof typeof CUSTOM_FIELDS_MAPPER
            ];

          return (
            Component && (
              <Component form={form} fieldSchema={field} key={field.id} />
            )
          );
        })
    );
  };

  const refetchWalletData = () => {
    queryClient.refetchQueries({
      queryKey: [
        'wallet',
        {
          walletId,
          organizationId: localStorage.getItem('organization') || '',
        },
      ],
    });
  };

  const holidays = [
    { day: 1, month: 0 }, // Ano Novo
    { day: 29, month: 2 }, // Paixão de Cristo
    { day: 21, month: 3 }, // Tiradentes
    { day: 1, month: 5 }, // Dia do Trabalho
    { day: 7, month: 8 }, // Independência
    { day: 12, month: 9 }, // Nossa Senhora Aparecida
    { day: 2, month: 10 }, // Finados
    { day: 15, month: 10 }, // Proclamação da República
    { day: 25, month: 11 }, // Natal
  ];

  function isHoliday(date: Date) {
    const month = date.getMonth();
    const day = date.getDate();

    return holidays.some(
      (holiday) => holiday.day === day && holiday.month === month
    );
  }

  return (
    <form
      onSubmit={handleSubmit((values) => authSubmit(() => submitForm(values)))}
      data-testid="pix-copy-paste"
    >
      {!isAuthentication && (
        <div className="flex flex-col gap-2">
          {contextualError && <ContextualError error={contextualError} />}

          <SidebarSection
            titleSection={t('payment-voucher.bank-slip-confirmation')}
            subTitleSection={t('payment-voucher.confirm-transaction-value')}
          >
            <ValueCardComponent
              key={
                form.watch('processingDate').toString() + isEditingValuePayment
              }
              currentValue={form.watch('amount')}
              setCurrentValue={(value) => {
                setHasEditedAmount(true);
                form.setValue('amount', value);
              }}
              isEditing={isEditingValuePayment}
              setIsEditing={setIsEditingValuePayment}
              icon={ICONS.barcode}
              isEditable={isAmountEditable}
              minAmount={paymentConfirm?.txnMinAmount}
              maxAmount={paymentConfirm?.txnMaxAmount}
            />
          </SidebarSection>

          <div className="flex flex-col">
            <p className="text-p3 text-neutral-80">
              {t('payment-voucher.transfer-type')}
            </p>
            <p className="text-p2 text-neutral-400">{t('general.bank-slip')}</p>
          </div>

          <hr className="hr" />

          <SidebarSection
            titleSection={t('payment-voucher.payment-date')}
            subTitleSection={t('payment-voucher.when-will-paid')}
          >
            {!paymentConfirm?.dueDate && (
              <div onClick={() => setIsEditingValuePayment(false)}>
                <DatePicker
                  size="md"
                  date={form.watch('processingDate')}
                  setDate={(date) =>
                    form.setValue('processingDate', date || new Date())
                  }
                  disabled={[
                    isHoliday,
                    {
                      before: dayjs().startOf('day').toDate(),
                    },
                    {
                      dayOfWeek: [0, 6],
                    },
                  ]}
                />
              </div>
            )}

            {paymentConfirm?.dueDate && (
              <div
                className={clsx({
                  '!w-[254px] [&>div]:mb-0': true,
                  '[&_option]:text-primary-500 [&_*]:text-primary-500':
                    !isAfterDueDate,
                  '[&_option]:text-neutral-80 [&_*]:text-neutral-80':
                    isAfterDueDate,
                })}
                onClick={() => setIsEditingValuePayment(false)}
              >
                <Select
                  label=""
                  options={[
                    {
                      value: dayjs().startOf('day').toDate().toISOString(),
                      label: `${dayjs().format('DD/MM/YYYY')} (Hoje)`,
                    },
                    {
                      value: dayjs(paymentConfirm?.dueDate)
                        .utc(false)
                        .toISOString(),
                      label: `${dayjs(paymentConfirm?.dueDate).utc(false).format('DD/MM/YYYY')} (Vencimento)`,
                    },
                  ]}
                  name="processingDate"
                  form={form}
                  onclick={(value) => {
                    form.setValue('processingDate', value as unknown as Date);

                    if (hasEditedAmount) return;

                    const isToday =
                      value.toString() ===
                      dayjs().startOf('day').toDate().toISOString();

                    if (isToday) {
                      return form.setValue(
                        'amount',
                        moneyMask(paymentConfirm?.txnUpdatedAmount)
                      );
                    }

                    form.setValue(
                      'amount',
                      moneyMask(paymentConfirm?.txnOriginalAmount)
                    );
                  }}
                  icon={ICONS.calendar}
                  disabled={isAfterDueDate}
                />
              </div>
            )}
          </SidebarSection>

          <hr className="hr" />

          <SidebarSection
            titleSection={t('general.payment-details')}
            subTitleSection={t('payment-voucher.see-bank-slip-data')}
          >
            <div className="grid grid-cols-2 gap-4">
              {paymentConfirm?.dueDate && (
                <>
                  <ValueWrapper
                    label={t('payment-voucher.due-date')}
                    value={dayjs(paymentConfirm?.dueDate)
                      .utc(false)
                      .format('DD/MM/YYYY')}
                  />

                  {paymentConfirm?.expiresAt && (
                    <ValueWrapper
                      label={t('payment-voucher.expired-date')}
                      value={dayjs(paymentConfirm?.expiresAt)
                        .utc(false)
                        .format('DD/MM/YYYY')}
                    />
                  )}
                </>
              )}

              <ValueWrapper
                className="col-span-2"
                label={t('general.barcode')}
                value={paymentConfirm?.barcode}
              />

              <ValueWrapper
                className="col-span-2"
                label={t('general.bank')}
                value={paymentConfirm?.issuer}
              />

              {paymentConfirm?.dueDate && (
                <>
                  <ValueWrapper
                    label={t('general.original-amount')}
                    value={moneyMask(paymentConfirm?.txnOriginalAmount)}
                  />

                  <ValueWrapper
                    label={t('general.discount')}
                    value={moneyMask(
                      isPaymentToday ? paymentConfirm?.txnDiscountAmount : 0
                    )}
                  />

                  <ValueWrapper
                    label={t('general.interest')}
                    value={moneyMask(
                      isPaymentToday ? paymentConfirm?.txnInterestAmount : 0
                    )}
                  />

                  <ValueWrapper
                    label={t('general.fine')}
                    value={moneyMask(
                      isPaymentToday ? paymentConfirm?.txnFineAmount : 0
                    )}
                  />

                  <ValueWrapper
                    label={t('payment-voucher.min-value')}
                    value={moneyMask(paymentConfirm?.txnMinAmount)}
                  />

                  <ValueWrapper
                    label={t('payment-voucher.max-value')}
                    value={moneyMask(paymentConfirm?.txnMaxAmount)}
                  />
                </>
              )}

              <ValueWrapper
                label={t('payment-voucher.total-to-pay')}
                value={form.watch('amount')}
              />

              <ValueWrapper
                className="col-span-2"
                label={t('payment-voucher.payee-name')}
                value={paymentConfirm?.payee}
              />

              <ValueWrapper
                label={t('payment-voucher.payer-name')}
                value={paymentConfirm?.payer}
              />

              <ValueWrapper
                label={t('payment-voucher.payer-document')}
                value={paymentConfirm?.payeeDocument}
              />
            </div>
          </SidebarSection>

          {customFields?.items && customFields?.items.length > 0 && (
            <>
              <hr className="hr" />

              <div className="mt-4">
                <SidebarSection
                  titleSection={t('customFields.drawerBlockTitle')}
                >
                  <Skeleton
                    isLoading={isLoadingCustomFields}
                    width={520}
                    height={30}
                  >
                    <Row>
                      <Column lg={12} md={12} sm={12}>
                        {renderCustomFields()}
                      </Column>
                    </Row>
                  </Skeleton>
                </SidebarSection>
              </div>
            </>
          )}

          <SidebarFooter>
            <Button
              type="button"
              variant="tertiary"
              size="large"
              data-testid="cancelPix"
              disabled={loadingCancelPayment}
              onClick={cancelPayment}
              isLoading={loadingCancelPayment}
            >
              {t('general.button.cancel')}
            </Button>

            <Button
              type="submit"
              size="large"
              data-testid="submitForm"
              className="button_submit"
              isLoading={isPendingBankSlipConfirmation}
              disabled={loadingCancelPayment || isExpired}
            >
              {isPaymentToday ? 'Realizar pagamento' : 'Agendar pagamento'}
            </Button>
          </SidebarFooter>
        </div>
      )}

      <InDrawerAuthentication
        isPending={isPendingBankSlipConfirmation}
        {...controller}
      />
    </form>
  );
};
