import {
  ClearFiltersQuery,
  DatePickerRangeQuery,
  EmptyStateBlock,
  MultiSelectQuery,
  Pagination,
  SearchQuery,
  TableColumnsFilter,
} from '@components';
import {
  emptyStatementTransactions,
  emptyStatementTransactionsWithFilter,
  errorState,
} from '@constants/emptyState';
import { useQueryParams } from '@hooks';
import { TableDemo } from '@portao3-web/ui';
import { useInfinityCardTransactions } from '@services/cards/hooks/useInfinityCardTransactions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCardTransactionsColumns } from './useCardTransactionsColumns';

interface CardTransactionsTableProps {
  walletId: string | undefined;
  cardId: string | undefined;
}

export const CardTransactionsTable = ({
  cardId,
  walletId,
}: CardTransactionsTableProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const { queryParams } = useQueryParams();

  const {
    data,
    isFetching,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isError,
  } = useInfinityCardTransactions({
    cardId,
    walletId: walletId || 'default',
    startDate: queryParams.get('startDate'),
    endDate: queryParams.get('endDate'),
    merchantName: queryParams.get('merchantName'),
    financialImpactType: queryParams.get('financialImpact'),
  });

  const { columns, filterColumnsProps } = useCardTransactionsColumns();

  const hasFilters =
    queryParams.get('merchantName') || queryParams.get('financialImpact');

  const financialImpactOptions = [
    {
      label: t('general.credit'),
      value: 'CREDIT',
    },
    {
      label: t('general.debit'),
      value: 'DEBIT',
    },
  ];

  const currentPageData =
    data?.pages?.[page]?.items?.map((item) => ({ ...item, id: item._id })) ||
    [];

  const hideNextPage = isLoading || currentPageData.length === 0;

  return (
    <div>
      <div className="flex gap-3 mb-4">
        <SearchQuery
          placeholder={t('general.description')}
          query="merchantName"
        />

        <MultiSelectQuery
          onlyOptions
          query="financialImpact"
          title={t('general.credit-debit')}
          type="single"
          options={financialImpactOptions}
        />

        <DatePickerRangeQuery size="md" />

        <ClearFiltersQuery queries={['merchantName', 'financialImpact']} />

        <TableColumnsFilter {...filterColumnsProps} />
      </div>

      <TableDemo
        columns={columns}
        data={currentPageData}
        isLoading={isFetching || isFetchingNextPage}
        emptyState={
          <>
            {!isError && !hasFilters && (
              <EmptyStateBlock details={emptyStatementTransactions} />
            )}

            {!isError && hasFilters && (
              <EmptyStateBlock details={emptyStatementTransactionsWithFilter} />
            )}

            {isError && <EmptyStateBlock details={errorState} />}
          </>
        }
        pinLastColumn
      />
      {!hideNextPage && (
        <Pagination
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          page={page}
          setPage={setPage}
          pageCount={data?.pages.length}
          className="flex justify-end mt-4"
        />
      )}
    </div>
  );
};
