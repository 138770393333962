import {
  ButtonMoreItems,
  CardDetails,
  DatePickerRangeQuery,
  EmptyStateBlock,
  HeaderCard,
  HeaderPage,
  ItemPreview,
} from '@components';
import {
  Button,
  Card,
  Column,
  DetailsCard,
  Dialog,
  DropDownList,
  Icon,
  Row,
  Sidebar,
  Skeleton,
  SlideShow,
  Table,
  TableBody,
  TableCol,
  TableHead,
  TableRow,
  Typography,
} from '@portao3-web/ui';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import {
  cardCompanyList,
  cardTransactionsTHead,
  initialStateTransactions,
  initialStateTransactionsPeriodCompany,
  lastTransactionsCompanyTHead,
} from '@constants/TransactionCompany';
import {
  emptyCardTransactions,
  emptyCards,
  errorState,
} from '@constants/emptyState';
import { ICONS } from '@constants/icons';
import { limitPerPage } from '@constants/limitPerPage';
import { CardItem } from '@interfaces/Card.interfaces';
import {
  Transactions,
  TransactionsResponse,
} from '@interfaces/Transactions.interfaces';
import { eventsHandler } from '@lib';
import { DrawerNewBankSlip, DrawerPixArea } from '@modules/drawer';
import { TransactionsModule } from '@modules/transactions';
import { useDrawer } from '@providers';
import { cardTransactions, companyCard } from '@services/cards';
import { getBalance } from '@services/organization';
import {
  billingAmountAuthorized,
  rejectBillingAmount,
} from '@utils/BillingAmoutAuthorized';
import { renderTableHeader } from '@utils/renderTableHeader';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './Company.styles.scss';
import { ExpenseChart } from './components/expenseChart';

export const Company = () => {
  const { t } = useTranslation();
  const { openDrawer } = useDrawer();

  const isMounted = useRef(false);
  const history = useNavigate();
  const [transactionsPeriodCompany, setTransactionsPeriodCompany] =
    useState<TransactionsResponse>(initialStateTransactionsPeriodCompany);
  const [cardCompany, setCardCompany] = useState<CardItem[]>(cardCompanyList);
  const [cardListTransactions, setCardListTransactions] = useState<
    Transactions[]
  >(initialStateTransactions);
  const [cardListTransactionsCached, setCardListTransactionsCached] = useState<
    Transactions[]
  >(initialStateTransactions);
  const [transactionsPeriodLoading, setTransactionsPeriodLoading] =
    useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const [cardListTransactionsLoading, setCardListTransactionsLoading] =
    useState(true);
  const [positionSlide, setPositionSlide] = useState(0);
  const [emptyCard, setEmptyCard] = useState(false);
  const [balance, setBalance] = useState(-1);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [errorServiceCardCompany, setErrorServiceCardCompany] = useState(false);
  const [errorServiceTransactionCard, setErrorServiceTransactionCard] =
    useState(false);
  const [nextPageCard, setNextPageCard] = useState<string>('');
  const [hideNextPageCard, setHideNextPageCard] = useState(true);
  const [newPaymentTicket, setNewPaymentTicket] = useState(false);

  const [isLoadingMoreItems, setIsLoadingMoreItems] = useState(false);
  const organization = localStorage.getItem('organization');

  const getTransactionCardCached = async () => {
    setCardListTransactions((prevData) => [
      ...prevData,
      ...cardListTransactionsCached,
    ]);
    setIsLoadingMoreItems(true);
    if (nextPageCard) {
      const response = await cardTransactions(
        cardCompany[positionSlide].id,
        limitPerPage,
        nextPageCard,
        undefined,
        undefined
      );
      setCardListTransactionsCached(response.items);

      setNextPageCard(response.next);
      setCardListTransactionsLoading(false);
      setIsLoadingMoreItems(false);
    } else {
      setHideNextPageCard(false);
      setIsLoadingMoreItems(false);
    }
  };

  const balanceOrganization = useCallback(async () => {
    setBalanceLoading(true);
    try {
      const balance = await getBalance();
      setBalance(balance.totalBalance);
      setBalanceLoading(false);
    } catch (error) {
      setBalanceLoading(false);
    }
  }, []);

  useLayoutEffect(() => {
    const getCardCompany = async () => {
      setCardLoading(true);
      setErrorServiceCardCompany(false);
      try {
        const response = await companyCard();
        const filteredCards = response.items.filter(
          (item) => item.status === 'ACTIVE'
        );
        if (response.items.length === 0 || filteredCards.length === 0) {
          setEmptyCard(true);
        } else {
          setCardCompany(filteredCards.slice(0, 3));

          setCardLoading(false);
        }
      } catch (error) {
        setCardLoading(false);
        setErrorServiceCardCompany(true);
      }
    };

    if (!isMounted.current) {
      getCardCompany();
      balanceOrganization();
      isMounted.current = true;
    }
  }, [setCardCompany, setCardLoading, organization, balanceOrganization]);

  useLayoutEffect(() => {
    const source = axios.CancelToken.source();
    const getTransactionCard = async () => {
      setCardListTransactionsLoading(true);
      setErrorServiceTransactionCard(false);
      const nextPage = '';

      try {
        const response = await cardTransactions(
          cardCompany[positionSlide].id,
          limitPerPage,
          nextPage,
          undefined,
          source
        );

        if (response.next) {
          const responseCached = await cardTransactions(
            cardCompany[positionSlide].id,
            limitPerPage,
            response.next,
            undefined,
            source
          );

          setCardListTransactionsCached(responseCached.items);
          setNextPageCard(responseCached.next);
        } else {
          setHideNextPageCard(false);
        }

        setCardListTransactions(response.items);

        setCardListTransactionsLoading(false);
      } catch (error: unknown) {
        const errorService = error as AxiosError;
        if (errorService && errorService.response?.status !== 500) return;

        setCardListTransactionsLoading(false);
        setErrorServiceTransactionCard(true);
      }
    };

    if (cardCompany[0].id !== -1) {
      getTransactionCard();
    }

    return () => {
      source.cancel();
    };
  }, [
    setCardListTransactions,
    setCardListTransactionsLoading,
    positionSlide,
    cardCompany,
  ]);

  const setPositionSlideCard = (index: number) => {
    setCardListTransactions(initialStateTransactions);
    setNextPageCard('');
    setPositionSlide(index);
  };

  const financialImpactType = (item: string) => {
    return item === 'NONE' ? 'line_through' : '';
  };

  return (
    <div className="company">
      <HeaderPage
        title={t('general.company-account')}
        subTitle="Sua visão geral da conta empresarial."
      >
        <DatePickerRangeQuery />

        <Dialog variant="popover">
          <Dialog.Trigger>
            <Button
              size="large"
              variant="primary"
              data-testid="newPayment"
              onClick={() =>
                eventsHandler.viewOptionsWalletTransactionWalletCompany()
              }
            >
              {t('button.actions')}
              <i className="fa-solid fa-chevron-down fa-1x" />
            </Button>
          </Dialog.Trigger>
          <Dialog.Content>
            <DropDownList
              list={[
                {
                  label: t('company.actions.pix-area'),
                  action: () =>
                    openDrawer(<DrawerPixArea walletId="default" />),
                  trackUserAction: () =>
                    eventsHandler.selectOptionAreaPixWalletCompany(),
                },
                {
                  label: t('company.actions.bank-slip-payment'),
                  action: () => setNewPaymentTicket(true),
                  trackUserAction: () =>
                    eventsHandler.selectOptionFundsOutBoletoWalletCompany(),
                },
                {
                  label: t('general.funds-in'),
                  action: () => openDrawer('funds-in', { walletId: 'default' }),
                  trackUserAction: () =>
                    eventsHandler.selectOptionFundsInWalletCompany(),
                },
              ]}
            />
          </Dialog.Content>
        </Dialog>
      </HeaderPage>
      <Row>
        <Column lg={8} md={12} className="column_gap">
          <Row>
            <Column lg={6} md={6}>
              <DetailsCard
                label="Saldo disponível"
                headerIcon="fa-regular fa-money-bills-simple"
                loading={balanceLoading}
                currencyValue={
                  balance !== -1
                    ? calcCurrencyValue(balance, 'BRL')
                    : 'Erro ao carregar o valor'
                }
                storageName="companyEntries"
                trackUserActions={{
                  hide: () => eventsHandler.hideButtonFundsInWalletCompany(),
                  show: () => eventsHandler.showButtonFundsInWalletCompany(),
                }}
              />
            </Column>
            <Column lg={6} md={6}>
              <DetailsCard
                label="Total de saídas no período"
                headerIcon="fa-regular fa-arrow-down"
                loading={transactionsPeriodLoading}
                currencyValue={
                  transactionsPeriodCompany
                    ? calcCurrencyValue(transactionsPeriodCompany.output, 'BRL')
                    : 'Erro ao carregar o valor'
                }
                valueType={
                  transactionsPeriodCompany?.output ? 'EXIT' : 'AVAILABLE'
                }
                storageName="companyExits"
                trackUserActions={{
                  hide: () => eventsHandler.hideButtonFundsOutWalletCompany(),
                  show: () => eventsHandler.showButtonFundsOutWalletCompany(),
                }}
              />
            </Column>
          </Row>

          <Card className="company_graph">
            <ExpenseChart organization={organization} />
          </Card>

          <Card className="company_auto">
            <HeaderCard title="Últimas transações no período" />
            <TransactionsModule
              thead={lastTransactionsCompanyTHead}
              setTransactionsPeriodLoading={setTransactionsPeriodLoading}
              transactionsPeriodLoading={transactionsPeriodLoading}
              setTransactionsPeriodCompany={setTransactionsPeriodCompany}
              transactionsPeriodCompany={transactionsPeriodCompany}
              endpoint={`/wallets/default`}
            />
          </Card>
        </Column>

        <Column lg={4} md={12} className="column_gap">
          <Card className="card_transaction_list">
            <HeaderCard title="Mais utilizado" />
            {!emptyCard && !errorServiceCardCompany && (
              <>
                <ItemPreview
                  variant="card"
                  data={cardCompany[positionSlide]}
                  isLoading={cardLoading}
                />
                <div className="company_card_slideshow">
                  <SlideShow getPositionSlide={setPositionSlideCard}>
                    {cardCompany.map((item: CardItem, index: number) => {
                      return (
                        <CardDetails
                          cardNumber={item.panMasked.slice(-4)}
                          cardId={item.id}
                          cardType={item.type}
                          verticalMode={true}
                          key={index}
                          showTypeCard={false}
                          walletId={item.wallet}
                        />
                      );
                    })}
                  </SlideShow>
                </div>
                <div
                  className="company_card_transactions"
                  data-testid="card_transactions"
                >
                  {!errorServiceCardCompany && (
                    <Table>
                      <TableHead>
                        <TableRow>
                          {cardTransactionsTHead.map(
                            (columnTitle: string, key: number) =>
                              renderTableHeader(columnTitle, key)
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!errorServiceTransactionCard &&
                          cardListTransactions.length > 0 &&
                          cardListTransactions.map((item, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCol className="td_icon">
                                  <Skeleton
                                    variant="circular"
                                    width={32}
                                    height={32}
                                    isLoading={cardListTransactionsLoading}
                                  >
                                    <div className="card_list_item_info--icon">
                                      <Icon size="xlarge">
                                        {item.cardTransaction && (
                                          <i className="fa-regular fa-credit-card"></i>
                                        )}
                                        {item.pixTransaction && (
                                          <i className="fa-brands fa-pix"></i>
                                        )}
                                        {item.feeTransaction && (
                                          <i className="fa-regular fa-file-invoice-dollar"></i>
                                        )}
                                      </Icon>
                                    </div>
                                  </Skeleton>
                                </TableCol>
                                <TableCol>
                                  <Skeleton
                                    width={'95%'}
                                    isLoading={cardListTransactionsLoading}
                                  >
                                    <Typography
                                      tag="p"
                                      color="var(--product-neutral-n500)"
                                      className={`card_list_item_info--totally ${financialImpactType(
                                        item.financialImpactType
                                      )}`}
                                      weight="p2"
                                    >
                                      {item.cardTransaction?.merchantName}
                                      {item.pixTransaction &&
                                        `PIX - ${item.pixTransaction.debitParty.name}`}
                                      {item.feeTransaction &&
                                        item.feeTransaction.description}
                                    </Typography>
                                    <Typography
                                      tag="p"
                                      color="var(--product-neutral-n100)"
                                      className={`card_list_item_info--totally ${financialImpactType(
                                        item.financialImpactType
                                      )}`}
                                      weight="p3"
                                    >
                                      {/* Cartão {item.card_number} */}
                                      {item.cardTransaction && 'Cartão'}
                                    </Typography>
                                  </Skeleton>
                                </TableCol>
                                <TableCol>
                                  <Skeleton
                                    width={'65%'}
                                    isLoading={cardListTransactionsLoading}
                                  >
                                    <Typography
                                      tag="p"
                                      color={`var(${
                                        item.financialImpactType === 'DEBIT'
                                          ? '--product-danger-d500'
                                          : '--product-neutral-n500'
                                      })`}
                                      className={`card_list_item_info--totally ${financialImpactType(
                                        item.financialImpactType
                                      )}`}
                                      weight="p2"
                                    >
                                      {item.financialImpactType === 'NONE'
                                        ? rejectBillingAmount(item)
                                        : billingAmountAuthorized(item)}
                                    </Typography>
                                  </Skeleton>
                                </TableCol>
                              </TableRow>
                            );
                          })}
                        {hideNextPageCard &&
                          !cardListTransactionsLoading &&
                          !errorServiceTransactionCard &&
                          cardListTransactions.length > 0 && (
                            <ButtonMoreItems
                              colSpan={3}
                              onClick={getTransactionCardCached}
                              isLoadingMoreItems={isLoadingMoreItems}
                            />
                          )}
                      </TableBody>
                    </Table>
                  )}
                  {cardListTransactions.length === 0 &&
                    !errorServiceTransactionCard &&
                    !cardListTransactionsLoading && (
                      <div className="space_empty_transaction">
                        <EmptyStateBlock details={emptyCardTransactions} />
                      </div>
                    )}
                  {errorServiceTransactionCard && (
                    <EmptyStateBlock details={errorState} />
                  )}
                </div>
              </>
            )}

            {emptyCard && !errorServiceCardCompany && (
              <EmptyStateBlock details={emptyCards} />
            )}

            {errorServiceCardCompany && (
              <EmptyStateBlock
                details={errorState}
                buttonLabel="Adicionar cartão"
                buttonAction={() => history('/cards')}
              />
            )}
          </Card>
        </Column>
      </Row>

      {newPaymentTicket && (
        <Sidebar
          title={t('company.actions.bank-slip-payment')}
          icon={ICONS.barcode}
          open={newPaymentTicket}
          onClose={() => setNewPaymentTicket(false)}
        >
          <DrawerNewBankSlip onClose={() => setNewPaymentTicket(false)} />
        </Sidebar>
      )}
    </div>
  );
};
