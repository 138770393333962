import { viewCategoryWallet } from '@enums/WalletEnum';
import { WalletCategory } from '@interfaces/Wallets.interfaces';
import { Icon, Skeleton, SlideShow, Typography } from '@portao3-web/ui';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { useEffect, useState } from 'react';
import { MyWalletProps } from './MyWallet.interface';
import './MyWallet.styles.scss';
import { renderIconCategory } from './utils/IconCategory';

export const MyWallet = ({
  walletCategoryList,
  totalBalance,
  setSelectedCategory = () => 0,
  isClickable = false,
  gridColumns,
  qtdCardsExhibition,
  isFetching = false,
}: MyWalletProps) => {
  const [walletCategory, setWalletCategory] = useState<WalletCategory[][]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<WalletCategory>();

  useEffect(() => {
    if (walletCategoryList) {
      setIsLoading(false);
      const categoryList = walletCategoryList.reduce(
        (result: WalletCategory[][], item, index) => {
          if (index % (qtdCardsExhibition || 6) === 0) {
            result.push([]);
          }
          result[result.length - 1].push(item);
          return result;
        },
        []
      );
      setWalletCategory(categoryList);
    } else {
      setIsLoading(true);
    }
  }, [qtdCardsExhibition, walletCategoryList]);

  const selectCategory = (balance: WalletCategory) => {
    setSelectedCategory(balance);
    setSelectedItem(balance);
  };

  const clickable = isClickable ? 'myWallet_category_item--clickable' : '';

  return (
    <div className="myWallet">
      <div className="myWallet__header">
        <Skeleton
          variant="rounded"
          width={82}
          isLoading={isLoading || isFetching}
        >
          <div className="myWallet__header_balance_title">
            <Icon size="xlarge">
              <i className="fa-regular fa-wallet"></i>
            </Icon>

            <Typography tag="h4" color="var(--product-neutral-n500)">
              Saldo total
            </Typography>
          </div>
        </Skeleton>
        <div className="myWallet__header_balance_value">
          <Skeleton
            variant="rounded"
            width={82}
            isLoading={isLoading || isFetching}
          >
            <Typography tag="h4" color="var(--product-neutral-n500)">
              {calcCurrencyValue(totalBalance ?? 0, 'BRL')}
            </Typography>
          </Skeleton>
        </div>
      </div>

      {!isLoading && !isFetching && (
        <SlideShow>
          {walletCategory.map((walletCategory, index) => {
            return (
              <div
                className="myWallet_category"
                key={index}
                style={{ gridTemplateColumns: `repeat(${gridColumns}, 1fr)` }}
              >
                {walletCategory.map(
                  (balance: WalletCategory, index: number) => {
                    return (
                      <div
                        className={`myWallet_category_item ${
                          balance === selectedItem
                            ? 'myWallet_category_item--active'
                            : ''
                        } ${clickable}`}
                        key={index}
                        onClick={() => selectCategory(balance)}
                        role={isClickable ? 'button' : undefined}
                        tabIndex={isClickable ? 0 : -1}
                        data-testid={isClickable ? balance.category : undefined}
                      >
                        <div
                          className={`myWallet_category_item_icon ${
                            renderIconCategory(balance.category)?.color
                          }`}
                        >
                          <Icon size="xlarge">
                            <i
                              className={`fa-regular fa-${
                                renderIconCategory(balance.category)?.icon
                              }`}
                            ></i>
                          </Icon>
                        </div>
                        <Typography
                          tag="p"
                          weight="p1"
                          color="var(--product-neutral-n500)"
                        >
                          {calcCurrencyValue(balance.amount, 'BRL')}
                        </Typography>
                        <Typography
                          tag="p"
                          weight="p2"
                          color="var(--product-neutral-n80)"
                          className="myWallet_category_item_overflow"
                          title={viewCategoryWallet[balance.category]}
                        >
                          {viewCategoryWallet[balance.category]}
                        </Typography>
                      </div>
                    );
                  }
                )}
              </div>
            );
          })}
        </SlideShow>
      )}

      {(isLoading || isFetching) && (
        <Skeleton
          variant="rounded"
          height={200}
          isLoading={isLoading || isFetching}
        >
          <Typography tag="h4" color="var(--product-neutral-n500)">
            Saldo total
          </Typography>
        </Skeleton>
      )}
    </div>
  );
};
