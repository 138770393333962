import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ContextualError,
  ContextualErrorType,
  SidebarFooter,
  SidebarSection,
} from '@components';
import { BillingType, FeeType } from '@enums/Billing.enum';
import { yupResolver } from '@hookform/resolvers/yup';
import { useV4Validator } from '@hooks';
import { CustomFieldLocation } from '@interfaces/CustomFields.interfaces';
import {
  Autocomplete,
  Button,
  ErrorMessage,
  Input,
  Skeleton,
} from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { billingKeys } from '@services/billing/hooks';
import { useCreateBillingMutation } from '@services/billing/hooks/useCreateBillingMutation';
import { useCustomFieldsQuery } from '@services/customFields/hooks';
import { useInfiniteCustomerListQuery } from '@services/customers/hooks';
import { useWalletQuery } from '@services/wallet/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { moneyMask, percentMask } from '@utils/formatMasks';
import { parseStringToIntNumber } from '@utils/parses';
import {
  optionalSchema,
  requiredSchema,
  validationSchema,
} from '@utils/validationSchema';
import { DrawerFundsInFlow } from '../DrawerFundsIn.interface';

interface FormCreateBillingProps {
  walletId: string | undefined;
  flow: DrawerFundsInFlow;
}

export const FormCreateBilling = ({
  walletId,
  flow,
}: FormCreateBillingProps) => {
  const defaultValues = {
    client: '',
    wallet: '',
    amount: '',
    date: '',
    interest: '',
    fine: '',
    description: '',
  };

  const organizationId = localStorage.getItem('organization');

  const { openDrawer, removeParams } = useDrawer();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const [customerInput, setCustomerInput] = useState('');
  const [customerQuery, setCustomerQuery] = useState('');
  const [error, setError] = useState<ContextualErrorType>(null);

  const isPix = flow === 'qr-code';
  const currentCustomer = sessionStorage.getItem('created-customer');

  const { data: wallet, isLoading: isLoadingWallet } = useWalletQuery({
    walletId,
    organizationId,
  });
  const { isFilialV4 } = useV4Validator();

  const { data: customFields, isLoading: isLoadingCustomFields } =
    useCustomFieldsQuery({
      organization: organizationId,
      location: CustomFieldLocation.WALLET,
    });

  const {
    data: customerList,
    fetchStatus: fetchCustomerStatus,
    isFetching: isFetchingCustomers,
    fetchNextPage: fetchMoreCustomers,
    isFetchingNextPage: isFetchingMoreCustomers,
  } = useInfiniteCustomerListQuery(organizationId, customerQuery);

  const customValidationSchema = {
    client: optionalSchema.client,
    fine: optionalSchema.fine,
    interest: optionalSchema.interest,
    date: isPix ? optionalSchema.futureDate : requiredSchema.futureDate,
    description: optionalSchema.description,
    wallet: optionalSchema.wallet,
    amount: requiredSchema.amount.test(
      'min-amount',
      'error.min-amount',
      (value) => {
        if (value) {
          return parseStringToIntNumber(value) > 100;
        }
      }
    ),
  };

  const form = useForm<typeof defaultValues>({
    defaultValues,
    resolver: yupResolver(
      validationSchema(
        defaultValues,
        customValidationSchema
      ) as unknown as never
    ),
  });

  const {
    formState: { errors },
  } = form;

  const { mutate: createBillingMutate, isPending: isPendingCreateBilling } =
    useCreateBillingMutation();

  const autocompleteCustomerList = useMemo(() => {
    const customerOptions: Record<string, string> = {};
    customerList?.forEach(
      (customer) =>
        (customerOptions[customer.id] = `${
          customer.trandingName ?? customer.name
        } - ${customer.email}`)
    );
    return customerOptions;
  }, [customerList]);

  const handleRedirectToCreateClient = () => {
    openDrawer('create-payer-client', {
      flow,
    });
  };

  const createBilling = (data: typeof defaultValues) => {
    const interestConvertedPercentPayload =
      parseStringToIntNumber(data.interest) / 10000;
    const fineConverted = parseStringToIntNumber(data.fine);

    const interest = data.interest
      ? {
          type: FeeType.PERCENT,
          value: interestConvertedPercentPayload,
          date: dayjs(data.date).add(1, 'day').format('YYYY-MM-DD'),
        }
      : undefined;

    const fine =
      +fineConverted > 0
        ? {
            type: FeeType.FIXED,
            value: fineConverted,
            date: dayjs(data.date).add(1, 'day').format('YYYY-MM-DD'),
          }
        : undefined;

    createBillingMutate(
      {
        walletId,
        params: {
          customer: data?.client,
          amount: parseStringToIntNumber(data.amount),
          dueDate: data?.date,
          description: data.description,
          type: isPix ? BillingType.PIX_QR_CODE : BillingType.BILLET,
          fine,
          interest,
        },
      },
      {
        onSuccess: (data) => {
          openDrawer('funds-in', {
            flow,
            billingId: data.id,
            walletId,
          });

          queryClient.invalidateQueries({
            queryKey: billingKeys.all(),
          });
        },
        onError: (error) => {
          setError({
            message: t('error.default'),
            traceId: error?.response?.data?.traceId,
          });
        },
      }
    );
  };

  const handleQueryCustomers = async (value = '') => {
    setCustomerQuery(value);
  };

  const handleSelectCustomer = (value: string) => {
    setCustomerInput(autocompleteCustomerList[value]);
    form.setValue('client', value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  useEffect(() => {
    if (isLoadingCustomFields || isLoadingWallet) return;

    if (currentCustomer) {
      const customer = JSON.parse(currentCustomer);
      handleSelectCustomer(customer.id);
      setCustomerInput(customer.name);
      sessionStorage.removeItem('created-customer');
    } else if (customFields) {
      const field = wallet?.customFields?.find(
        (field) => field.identifier === 'Cliente'
      );

      if (field) {
        handleSelectCustomer(field.values[0]);
        setCustomerInput(field.values[1]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomer, isLoadingCustomFields]);

  return (
    <form onSubmit={form.handleSubmit(createBilling)}>
      <ContextualError error={error} />

      <SidebarSection
        titleSection={t('billing.give-billing-details')}
        subTitleSection={''}
        numberSection={1}
      >
        <div className="flex flex-row gap-4">
          <Input
            label={t('general.value')}
            placeholder="Ex: R$ 1.000,00"
            name="amount"
            register={form.register}
            error={!!errors['amount']}
            onChange={({ target }) => {
              form.setValue('amount', moneyMask(target.value));
            }}
          >
            {errors['amount']?.message && (
              <ErrorMessage
                message={t(errors['amount'].message.toString(), '')}
              />
            )}
          </Input>

          <Input
            label={t('general.due-date')}
            name="date"
            type="date"
            register={form.register}
            error={!!errors['date']}
            modifiedCalendar
            form={form}
          >
            {errors['date']?.message && (
              <ErrorMessage
                message={t(errors['date'].message.toString(), '')}
              />
            )}
          </Input>
        </div>

        <div className="flex flex-row gap-4">
          <Input
            label={t('billing.interest-daily')}
            placeholder="Ex: 1%"
            name="interest"
            register={form.register}
            error={!!errors['interest']}
            onChange={({ target }) => {
              const newValue = percentMask(target?.value);

              form.setValue('interest', newValue);
            }}
          >
            {errors['interest']?.message && (
              <ErrorMessage
                message={t(errors['interest'].message.toString(), '')}
              />
            )}
          </Input>

          <Input
            label={t('general.fine')}
            placeholder="Ex: R$ 10,00"
            name="fine"
            register={form.register}
            error={!!errors['fine']}
            onChange={({ target }) => {
              form.setValue('fine', moneyMask(target.value));
            }}
          />
        </div>

        <Input
          label={t('general.description')}
          placeholder="Ex: Mensalidade"
          name="description"
          register={form.register}
          error={!!errors['description']}
          maxLength={20}
        />
      </SidebarSection>

      <SidebarSection
        titleSection={t('billing.who-charge')}
        subTitleSection={''}
        numberSection={2}
      >
        <div className="mb-4 flex flex-col gap-1">
          <Skeleton height={62} isLoading={isLoadingCustomFields}>
            <Autocomplete
              label={t('general.select-customer')}
              value={customerInput}
              setValue={setCustomerInput}
              placeholder="Busque por nome"
              items={autocompleteCustomerList}
              selectItem={handleSelectCustomer}
              queryFn={handleQueryCustomers}
              isFetching={isFetchingCustomers}
              isFetchingNextPage={isFetchingMoreCustomers}
              delay={500}
              fetchNextPage={
                fetchCustomerStatus === 'fetching'
                  ? undefined
                  : fetchMoreCustomers
              }
              error={errors['client']?.message?.toString()}
            />
          </Skeleton>
        </div>

        {!isFilialV4 && (
          <Button
            variant="secondary"
            size="small"
            className="ml-auto mb-4 w-max"
            onClick={handleRedirectToCreateClient}
          >
            {t('customers.add-customer')}
          </Button>
        )}
      </SidebarSection>

      <SidebarFooter>
        <Button
          type="button"
          variant="tertiary"
          size="large"
          onClick={() => removeParams(['flow'])}
          disabled={isPendingCreateBilling}
        >
          {t('button.back')}
        </Button>

        <Button
          size="large"
          type="submit"
          variant="primary"
          isLoading={isPendingCreateBilling}
        >
          Gerar cobrança
        </Button>
      </SidebarFooter>
    </form>
  );
};
